// Importa los módulos correctos de Firebase
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'; // Importa el módulo de autenticación

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAd6IORS1YPzTxcfd-wLxdn577-M8qGV-o",
  authDomain: "eighth-feat-435122-q2.firebaseapp.com",
  databaseURL: "https://eighth-feat-435122-q2-default-rtdb.firebaseio.com",
  projectId: "eighth-feat-435122-q2",
  storageBucket: "eighth-feat-435122-q2.appspot.com",
  messagingSenderId: "17708082324",
  appId: "1:17708082324:web:8cc6720163832309010081"
};

// Inicializa Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Inicializa Firestore
const db = getFirestore(firebaseApp);

// Inicializa Auth
const auth = getAuth(firebaseApp);

export { db, auth };
